<template>
    <v-dialog v-model="isPictureShow" :width="maxWidth">
      <v-card>
        <v-img
            :width="maxWidth"
            class="white--text align-end"
            :src="pictureData">
        </v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#559897" @click="deleteEvent" dark v-if="del">
            刪除
          </v-btn>
          <v-btn color="#559897" @click="downloadEvent" v-if="download" dark>
            下載
          </v-btn>
          <v-btn color="#559897" @click="closeDialog" dark>
            關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "pictureDialog",
  props: ['showPicture','pictureData','isDelete', 'isDownload','width','pictureFileName'],
  created() {
    this.isPictureShow = this.showPicture;
  },
  data: () => ({
    isPictureShow: false,
    del: true,
    download: true,
    maxWidth: 300,
  }),
  methods: {
    closeDialog: function() {
      this.$emit('pictureEvent', false);
    },
    deleteEvent: function() {
      this.$emit('pictureDelete', true);
    },
    downloadEvent: function() {
      var blob = this.dataURLtoBlob(this.pictureData);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.pictureFileName;
      a.click();
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
  watch: {
    showPicture: function(value){
      //console.log("showPicture value = " + value);
      this.isPictureShow = value;
      
      if (typeof this.isDelete !== "undefined") {
        this.del = false;
      }

      if (typeof this.isDownload !== "undefined") {
        this.download = false;
      }

      if (typeof this.width !== "undefined") {
        this.maxWidth = this.width;
      }
      //console.log(this.maxWidth);
    },
    isPictureShow: function(value) { //按到灰色區域關閉conform，就回覆取消的event
      //console.log("isPictureShow value = " + value);
      if (value === false){
        this.$emit('pictureEvent', false);
      }
    }
  }
};
</script>