<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                              <v-col class="px-10">
                                <v-select
                                  :items="orgList"
                                  v-model="queryRequest.queryObject.orgId"
                                  label="事業單位名稱"
                                  v-if="loginAuth.role === 'S'"
                                  @input="getSubUnitList"
                                  clearable
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="px-10">
                                  <v-text-field label="編號"
                                      v-model="uIdno"
                                      outlined 
                                      hide-details>
                                  </v-text-field>
                              </v-col>
                              <v-col class="px-10">
                                  <v-text-field label="姓名" 
                                      v-model="uName"
                                      outlined 
                                      hide-details>
                                  </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="px-10">
                                <v-combobox
                                  :items="subUnitList"
                                  label="子單位"
                                  multiple
                                  chips
                                  v-model="subUnit"
                                ></v-combobox>
                              </v-col>
                            </v-row>
                            <br/>
                            
                            <v-row class="mb-n10">
                                <v-col sm="4" class="px-10 mt-2">
                                    <v-btn small class="white--text" color="#37906C" @click="toCreateUser()">
                                        <v-icon >mdi-note-plus</v-icon>新增人員
                                    </v-btn>
                                    <v-btn small class="white--text" color="#FF5151" @click="deleteMember()" v-if="deleteSelect.length > 0">
                                        <v-icon >mdi-delete</v-icon>刪除人員
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <div class="v-data-footer">
                                        <div class="v-data-footer__select">每頁列數:<v-select
                                        v-model="listPageSize"
                                        :items="pageSizeList"
                                        dense
                                        @change="nextPage(1,listPageSize)"
                                        ></v-select></div>
                                        <div class="v-data-footer__pagination">
                                        共 {{pageInfo.totalCount}} 筆資料，全部 {{pageInfo.maxPage}} 頁，目前第{{pageInfo.currentPage}}頁
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage-1, listPageSize)" :disabled="pageInfo.currentPage == 1">mdi-chevron-left</v-icon>
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage+1, listPageSize)" :disabled="pageInfo.currentPage >= pageInfo.maxPage">mdi-chevron-right</v-icon>
                                        </div>
                                    </div>
                                 </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-data-table
                                    v-model="deleteSelect"
                                    :headers="headers"
                                    :items="datalist"
                                    :items-per-page="listPageSize"
                                    item-key="orgmCode"
                                    height="800px"
                                    hide-default-footer
                                    show-select
                                    disable-sort
                                    class="mx-12">
                                        <template v-slot:[`item.photo`]={item}>
                                            <v-icon @click.stop="toShowPic(item)">mdi-account-box-outline</v-icon>
                                        </template>
                                        <template v-slot:[`item.orgdel`]={item}>
                                            <v-icon @click.stop="toDel(item)">mdi-delete-forever</v-icon>
                                        </template>
                                        <template v-slot:[`item.orgedit`]={item}>
                                            <v-icon @click.stop="toEdit(item)" >mdi-square-edit-outline</v-icon>
                                        </template>
                                        <template v-slot:[`item.subUnit`]={item}>
                                            <div  v-for="(suItem, index) in item.subUnit" :key="index">
                                              {{suItem}}
                                            </div>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <br/>
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
    </v-container>
    <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
    <pictureDialog :showPicture="isPictureDialog" :pictureData="pictureData"  @pictureEvent="setPictureData($event)" isDownload="false" isDelete="false" width="500"/>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import loadingMask from '@/components/public/loadingMask.vue'
import pictureDialog from '@/components/public/pictureDialog.vue'
import commonMixin from '@/utils/commonMixin';
import {getOrgnizationMemberList, getOrgnizationNameSelectOption, getOrgnizationData, deleteOrgnizationMember, downloadFile} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationMemberList',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    console.log("loginAuth" + JSON.stringify(this.loginAuth));
    this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
    };
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    headers() {
      return [
          { text: '編號', value: 'orgmCode', class: "subtitle-2 font-weight-black",width:70,},
          { text: '姓名', value: 'name', class: "subtitle-2 font-weight-black",},
          { text: '照片', value: 'photo', class: "subtitle-2 font-weight-black",width:70,},
          { text: '子單位', value: 'subUnit', class: "subtitle-2 font-weight-black",width:120,},
          { text: '建立日期', value: 'createDate', class: "subtitle-2 font-weight-black",width:100,},
          { text: '維護', value: 'orgedit', class: "subtitle-2 font-weight-black",width:50,},
          { text: '刪除', value: 'orgdel', class: "subtitle-2 font-weight-black",width:50, },
      ]
    },
  },
  mounted(){
    this.userName = this.loginAuth.memberName;
    console.log("this.loginAuth.role = " + this.loginAuth.role);
    if (this.loginAuth.role === "S") {
      this.getOrgList();
    } else {
      this.getSubUnitList();
    }
    this.getDataList();
  },
  methods: {
    getDataList: function() { 
      var vm = this;
      vm.isLoading = true;
      console.log("vm.queryRequest = " + JSON.stringify(vm.queryRequest));
      getOrgnizationMemberList(vm.queryRequest, vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          console.log("response = " + JSON.stringify(responseData));

          if (responseData.statusCode === "1") {
              vm.datalist = responseData.queryPager.resultList;

              vm.datalist = vm.datalist.map(function(item){
                item.subUnit = item.subUnitRelationshipList.map(function(subItem){
                  return subItem.suName;
                });
                return item;
              });

              console.log("datalist = " + JSON.stringify(vm.datalist));
              vm.pageInfo.maxPage = responseData.queryPager.maxPage;
              vm.pageInfo.currentPage = responseData.queryPager.currentPage;
              vm.pageInfo.totalCount = responseData.queryPager.totalCount;
          } else {
              vm.datalist = [];
              if (responseData.statusCode !== "2") vm.$swal(responseData.message);
          }
        }
        vm.isLoading = false;
      });
    },
    getOrgList: function(){
      var vm = this;

      getOrgnizationNameSelectOption(vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          console.log("getOrgList = " + JSON.stringify(responseData));
          vm.orgList = responseData.orgnizationNameSelectOption.map(function(item) {
            var obj = new Object();
            obj.text = item.optionText;
            obj.value = item.optionValue;
            return obj;
          });

          console.log("orgList = " + JSON.stringify(vm.orgList));
        }
      });
    },
    getSubUnitList: function() {
      var vm = this;
      
      if (this.loginAuth.role === "S") {
        if (vm.queryRequest.queryObject.orgId !== null) {
          getOrgnizationData(vm.queryRequest.queryObject.orgId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
              var responseData = response.data;
              console.log("getOrgnizationData = " + JSON.stringify(responseData));
              vm.subUnitList = responseData.orgnization.subUnitList.map(function(item) {
                var obj = new Object();
                obj.text = item.suName;
                obj.value = item.suName;
                return obj;
              });
              //console.log("subUnitList = " + JSON.stringify(vm.subUnitList));
            }
          });
        }
        
        vm.getDataList();
      } else if (this.loginAuth.role === "O") {
        vm.subUnitList = this.loginAuth.orgnization.subUnitList.map(function(item) {
          var obj = new Object();
          obj.text = item.suName;
          obj.value = item.suName;
          return obj;
        });
      } else {
        //console.log(this.loginAuth);
        vm.subUnitList = this.loginAuth.subUnitRelationshipList.map(function(item) {
          var obj = new Object();
          obj.text = item.suName;
          obj.value = item.suName;
          return obj;
        });
      }

    },
    keyupDelay: function() {
        //用於延遲查詢，避免value改變時連續送出request
        let vm = this;
        clearTimeout(vm.timer);
        
        vm.timer = setTimeout(function() {
            vm.getDataList();
        }, 800);
    },
    toCreateUser: function() {
      this.$router.push({
        path: "/orgnizationMember/orgnizationMemberEdit/init"
      });
    },
    toEdit: function(item) {
      this.$router.push({
        path: "/orgnizationMember/orgnizationMemberEdit/" + item.orgmId
      });
    },
    toDel: function(item){
      this.deleteSelect = [];
      this.deleteSelect.push(item);
      this.deleteMember(); 
    },
    deleteMember: function(){
      var vm = this;
      vm.$swal({
          title: "<font face='Microsoft JhengHei'>請確認</font>",
          html: "<font face='Microsoft JhengHei'>確認後將立即刪除</font>",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "<font face='Microsoft JhengHei' color='#fff'>確認</font>",
          cancelButtonText: "<font face='Microsoft JhengHei' color='#fff'>取消</font>",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false
      }).then(function(result) {
          if (result.isConfirmed) {
              vm.isLoading = true;

              var objData = new Object();
              objData.orgmIdList = vm.deleteSelect.map(function(item){
                return item.orgmId;
              });

              deleteOrgnizationMember(objData, vm.auth).then(function(response) {
                if ("200" == response.status) { 
                  var responseData = response.data;
                  vm.deleteSelect = [];
                  vm.$swal(responseData.message);

                  vm.getDataList();
                }
                vm.isLoading = false;
              });

              //console.log("orgmIdList = " + JSON.stringify(objData.orgmIdList));
          } else {
            vm.deleteSelect = [];
          }
      });
    },
    toShowPic: function(value){
      console.log("value = " + JSON.stringify(value));
      var vm = this; 
      var picAuth = {
          headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
          },
          responseType: 'blob',
      };
      vm.pictureSelected = value.photo;
      console.log("go");
      downloadFile(value.photo, picAuth).then(function(response) {
          if ("200" == response.status) {
              console.log("showPicture");
              var blob=new Blob([response.data]);
              vm.blobToDataURI(blob,  function (data) {
                  vm.pictureData = data;
                  vm.isPictureDialog = true;
              });
          }
      });
    },
    setPictureData: function(value) {
        console.log(value);
        this.isPictureDialog = false;
        this.pictureSelected = null;
    },
    nextPage: function(currentPage, pageSize) {
      //console.log("next page = " + value);
      let vm = this;
      vm.queryRequest.queryPager.pageSize = pageSize;
      vm.queryRequest.queryPager.currentPage = currentPage;
      vm.getDataList();
    },
  },
  watch: {
    uName: function(value){
      this.queryRequest.queryObject.name = value;
      this.keyupDelay();
    },
    uIdno: function(value){
      this.queryRequest.queryObject.orgmCode = value;
      this.keyupDelay();
    },
    deleteSelect: function(){
      console.log(this.deleteSelect);
    },
    subUnit: function(value) {
      var subUnitName = value.map(function(item){
        return item.value;
      });
      console.log("subUnitName = " + JSON.stringify(subUnitName));
      this.queryRequest.queryObject.subUnitName = subUnitName;
      this.keyupDelay();
    },
  },
  data: () => ({
    auth: {},
    userName: "",
    isLoading: false, //loading畫面的開關
    datalist: [],
    orgList: [],
    subUnitList: [],
    subUnit: [],
    deleteSelect: [],
    singleSelect: false,
    pictureSelected: null,
    pictureData: null,
    isPictureDialog: false,
    uName: "",
    uIdno: "",
    pageInfo: {
      maxPage: 1,
      currentPage: 1,
      totalCount: 0,
    },
    queryRequest: {
      queryObject: {
        orgmCode: "",
        name: "",
        orgId: "",
        subUnitName: [],
      },
      queryPager: {
          currentPage: 1,
          pageSize: 10,
      },
    },
    listPageSize: 10,
    pageSizeList: [
      {text:"10", value:10},
      {text:"30", value:30},
      {text:"50", value:50},
    ],
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
    pictureDialog,
  },
}
</script>

<style scoped>

</style>